import React from "react";
import {
  Grid,
} from "@mui/material";

import ProductListing from "./product-listing.component";
import ProductImage from "./product-image.component";
import ItemSeperator from "./item-seperator.component";

const Item: React.FC<{
  id: number;
  title: string;
  price: string;
  description: string;
  externalUrl: string;
  picture: string;
  tags: string[];
  first: boolean;
  last: boolean;
  flip: boolean;
}> = ({ id, title, price, description, externalUrl, picture, tags, first, last, flip }) => {

  const FlippedDetails: React.FC<{
    toggleSide: boolean
  }> = ({toggleSide}) => {
    return (
      <>
        {toggleSide
          ? <ProductImage title={title} picture={picture} first={first} last={last} />
          : <ProductListing
              id={id} title={title} price={price} description={description}
              externalUrl={externalUrl} tags={tags} flip={flip} first={first}
            />
        }
      </>
    );
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid container direction="column" alignItems="flex-end"
        item xs={5}
        sx={{
          height: "100%"
        }}
      >
        <FlippedDetails toggleSide={flip} />
      </Grid>
      <Grid container justifyContent="center"
        item xs={2}
        sx={{ height: "100%" }}
      >
        <ItemSeperator first={first} last={last}></ItemSeperator>
      </Grid>
      <Grid container direction="column" alignItems="flex-start"
        item xs={5}
        sx={{
          height: "100%"
        }}
      >
        <FlippedDetails toggleSide={!flip} />
      </Grid>
    </Grid>
  );
};

export default Item;