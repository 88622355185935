import React from "react";
import { styled, } from "@mui/material/styles";
import {
  Typography,
  TypographyProps,
  SxProps,
  TypographyVariant
} from "@mui/material";

interface QuoteProps extends TypographyProps {
  children?: React.ReactNode;
  variant?: TypographyVariant;
  sx?: SxProps;
}

const Quote = ({
  children,
  variant = "body1",
  sx,
  ...props
}: QuoteProps): JSX.Element => {

  const defaultStyling = {
    fontFamily: "'Marck Script', cursive",
    fontWeight: "bold",
    fontSize: "18px",
    ...sx
  }

  return (
    <Typography
      variant={variant}
      sx={defaultStyling}
      component="span"
      {...props}
    >
      {children}
    </Typography>
  );
};

export default Quote;