import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  CardMedia,
} from "@mui/material";

const ProductImage: React.FC<{
  title: string;
  picture: string;
  first?: boolean;
  last?: boolean;
}> = ({ title, picture, first = false, last = false }) => {

  return (
      <Box
        sx={{
          paddingTop: (first ? "100px" : "30px"),
          paddingBottom: (last ? "100px" : "30px"),
        }}
      >
        <Card sx={{ padding: "5px" }}>
          <CardMedia component="img"
            image={picture}
            alt={title}
            sx={{
              height: "400px",
              width: "300px",
              overflow: "hidden"
            }}
          />
        </Card>
      </Box>
    );
}

export default ProductImage;