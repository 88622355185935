import React from "react";
import { styled, } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  BoxProps
} from "@mui/material";

import SpokeOutlinedIcon from '@mui/icons-material/SpokeOutlined';

const ItemSeperator: React.FC<{
  first?: boolean;
  last?: boolean;
}> = ({ first = false, last = false }) => {

  const smallAndUp = useMediaQuery('(min-width:600px)');

  const VerticalLineBox = styled(Box)<BoxProps>(({ theme }) => ({
    height: "99.6%",
    width: "1px",
    border: "2.5px dashed rgb(150 153 155 / 60%)",
    borderTopStyle: "none",
    borderRightStyle: "none",
    borderBottomStyle: "none",
  }));
  
  const TopCapIcon = styled(SpokeOutlinedIcon)(({ theme }) => ({
    color: "rgb(150 153 155 / 60%)",
    position: "absolute",
    width: "30px",
    height: "30px",
    top: "-30px",
    left: "-14.2px"
  }));
  
  const BottomCapIcon = styled(SpokeOutlinedIcon)(({ theme }) => ({
    color: "rgb(150 153 155 / 60%)",
    position: "absolute",
    width: "30px",
    height: "30px",
    transform: "rotate(180deg)",
    bottom: "-30px",
    left: "-13.8px"
  }));
  
  const HorizontalCircleBox = styled(Box)<BoxProps>(({ theme }) => ({
    backgroundColor: "#f77532",
    width: "20px",
    height: "20px",
    borderRadius: "20px",
    border: "2.5px solid white",
    position: "absolute",
    top: "34px",
    left: "47%"
  }));
  
  const HorizontalLineBox = styled(Box)<BoxProps>(({ theme }) => ({
    height: "1px",
    width: "70vw",
    border: "2.5px dashed rgb(150 153 155 / 60%)",
    borderLeftStyle: "none",
    borderRightStyle: "none",
    borderBottomStyle: "none",
  }));
  
  const LeftCapIcon = styled(SpokeOutlinedIcon)(({ theme }) => ({
    color: "rgb(150 153 155 / 60%)",
    position: "absolute",
    width: "30px",
    height: "30px",
    transform: "rotate(270deg)",
    top: "31px",
    left: "-30px"
  }));
  
  const RightCapIcon = styled(SpokeOutlinedIcon)(({ theme }) => ({
    color: "rgb(150 153 155 / 60%)",
    position: "absolute",
    width: "30px",
    height: "30px",
    transform: "rotate(90deg)",
    top: "31px",
    right: "-30px"
  }));

  const VerticalCircleBox = styled(Box)<BoxProps>(({ theme }) => ({
    backgroundColor: "#f77532",
    width: "20px",
    height: "20px",
    borderRadius: "20px",
    border: "2.5px solid white",
    position: "absolute",
    top: (first ? "125px" : "55px"),
    left: "-11px"
  }));

  return (
    <div style={{ position: "relative" }}>
      {smallAndUp
        ? <>
          <VerticalCircleBox />
          {first &&
            <TopCapIcon />
          }
          <VerticalLineBox />
          {last &&
            <BottomCapIcon />
          }
        </>
        : <>
          {!last &&
            <>
              <div style={{ paddingBottom: "45px" }}></div>
              <HorizontalCircleBox />
              <LeftCapIcon />
              <HorizontalLineBox />
              <RightCapIcon />
            </>
          }
        </>
      }
    </div>
  );
}

export default ItemSeperator;