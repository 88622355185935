import React from "react";
import { styled } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Grid,
  Stack,
  StackProps,
  Typography
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import Item from "./components/item.component";
import ItemMobile from "./components/item-mobile.component";
import Quote from "./components/quote.component";
import { Listing } from "./services/models";

const Listings: React.FC<{}> = () => {
  const listingUrl = `${window.location.origin}\\data\\listings.json`;

  const smallAndUp = useMediaQuery('(min-width:600px)');

  const RootStack = styled(Stack)<StackProps>(({ theme }) => ({
    [theme.breakpoints.only("xs")]: {
      paddingTop: "40px"
    },
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "20px"
    },
  }));
  
  const TitleStack = styled(Stack)<StackProps>(({ theme }) => ({
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "100px",
      paddingBottom: "20px",
    },
  }));

  const query = useQuery<Listing[]>(["inventory"], async () => {
    const response = await fetch(listingUrl);

    if (!response.ok) {
      throw new Error("Problem fetching data");
    }

    return await response.json();
  })

  const itemList = (items: Listing[]) =>
    items?.map((value: Listing, index: number, array: Listing[]) => {
      if (smallAndUp) {
        return (
          <Item key={value.key} id={value.key} title={value.title}
            price={value.price} description={value.description}
            externalUrl={value.externalUrl} picture={value.picture}
            tags={value.tags} first={index === 0}
            last={index === array.length-1} flip={index % 2 == 0}
          />
        );
      } else {
        return (
          <ItemMobile key={value.key} id={value.key} title={value.title}
            price={value.price} description={value.description}
            externalUrl={value.externalUrl} picture={value.picture}
            tags={value.tags} first={index === 0}
            last={index === array.length-1}
          />
        )
      }
    });

  return (
    <RootStack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={(smallAndUp ? 8 : 0)}
    >
      <TitleStack
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="h3"
          sx={{
            fontFamily: "'Fascinate Inline', cursive",
            paddingBottom: {
              xs: 0,
              sm: "20px"
            },
            display: {
              xs: "none",
              sm: "block"
            }
          }}
        >
          LotteColour
        </Typography>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{
            width: {
              xs: "80%",
              sm: "60%"
            }
          }}
        >
          <Quote>
            <p>
              <Quote sx={{fontSize: "40px", lineHeight: "0.5"}}>"</Quote>
              Far over the misty mountains cold
              To dungeons deep and caverns old
              We must away ere break of day
              To seek the pale enchanted gold.
            </p>
            <p>
              The dwarves of yore made mighty spells,
              While hammers fell like ringing bells
              In places deep, where dark things sleep,
              In hollow halls beneath the fells.
              <Quote sx={{fontSize: "40px", lineHeight: "0.5"}}>"</Quote>
            </p>
          </Quote>
        </Stack>
      </TitleStack>
      <Grid
        container
        sx={{
          paddingRight: "2px",
          paddingLeft: "2px",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >

        {!query.isLoading &&
          itemList(query.data)
        }
      </Grid>
    </RootStack>
  );
};

export default Listings;
