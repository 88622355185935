import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Listings from "./listings.component";

const queryClient = new QueryClient()

const root = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Listings />
    </QueryClientProvider>
  );
};

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: root,
  errorBoundary(err, info, props) {
    const logging = (message) => {
      console.log(`[@lotte-colour/listings] ${message}`);
    };

    logging(err);

    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
