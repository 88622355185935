import React, { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Button,
  ButtonProps,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";

import ShareIcon from "@mui/icons-material/Share";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const ProductListing: React.FC<{
  id: number;
  title: string;
  price: string;
  description: string;
  externalUrl: string;
  tags: string[];
  flip?: boolean;
  first?: boolean;
}> = ({ id, title, price, description, externalUrl, tags, flip = true, first = false }) => {

  const smallAndUp = useMediaQuery('(min-width:600px)');

  const ActionButton = styled(Button)<ButtonProps>(({ theme }) => ({
    borderRadius: "20px"
  }));

  const ProductInformationStack = styled(Stack)<StackProps>(({ theme }) => ({
    padding: 10,
    [theme.breakpoints.only("xs")]: {
      paddingTop: 0
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: (first ? "120px" : "50px")
    },
  }));

  const handleBuy = () => {
    window.location.href = externalUrl
  }

  const handleShare = () => {
    if (!navigator.share) return;

    navigator.share({
      url: externalUrl,
      title: title,
      text: "Checkout this awesome miniature on LotteColour!"
    })
  }

  return (
    <ProductInformationStack
      direction="column"
      alignItems={(!smallAndUp ? "center" :
        (flip ? "flex-start" : "flex-end")
      )}
      spacing={0}
      sx={{ textAlign: (!smallAndUp ? "center" :
        (flip ? "left" : "right")
      )}}
    >
      <Typography variant="h5" sx={{ paddingBottom: "10px"}}>
        {title}
      </Typography>
      <Typography variant="subtitle2"
        sx={{
          fontWeight: "bolder",
          color: "#f77532",
          paddingBottom: "10px"
        }}
      >
        ${price}
      </Typography>
      <Typography variant="body2">{description}</Typography>
      <Stack
        direction="row"
        justifyContent={(!smallAndUp ? "center" :
          (flip ? "flex-start" : "flex-end")
        )}
        alignItems="center"
        sx={{ paddingTop: "20px", width: "100%" }}
        spacing={3}
      >
        <ActionButton variant="contained"
          startIcon={<LocalOfferIcon />}
          sx={{
            backgroundColor: "#2a8a92",
            "&:hover": {
              backgroundColor: "#68b8c1",
            }
          }}
          onClick={handleBuy}
        >
          Buy
        </ActionButton>
        <ActionButton variant="text"
          startIcon={<ShareIcon sx={{ color: "#f77532" }} />}
          sx={{
            color: "black"
          }}
          onClick={handleShare}
        >
          Share
        </ActionButton>
      </Stack>
    </ProductInformationStack>
  );
}

export default ProductListing;