import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Stack
} from "@mui/material";
import ProductImage from "./product-image.component";
import ProductListing from "./product-listing.component";
import ItemSeperator from "./item-seperator.component";

const ItemMobile: React.FC<{
  id: number;
  title: string;
  price: string;
  description: string;
  externalUrl: string;
  picture: string;
  tags: string[];
  first: boolean;
  last: boolean;
}> = ({ id, title, price, description, externalUrl, picture, tags, first, last }) => {

  const smallAndUp = useMediaQuery('(min-width:600px)');

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ padding: "15px" }}
    >
      <ProductImage title={title} picture={picture} />
      <ProductListing
        id={id} title={title} price={price} description={description}
        externalUrl={externalUrl} tags={tags}
      />
      {!smallAndUp &&
        <ItemSeperator last={last} />
      }
    </Stack>
  );
};

export default ItemMobile;